import React from "react";
import { useSpring, animated } from "react-spring";
import "./home.css";
import Zoom from 'react-reveal/Zoom'
import { StaticImage } from "gatsby-plugin-image";
import TopScroll from "../components/TopScroll";

export default function Content() {

  const h2 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 2000 },
  });

  return (
    <animated.div id="projects" style={h2}>
      <TopScroll />
      <h1>Projects</h1>
      <Zoom>
        <div id="project-container" className="status1">
          <div className="project-text">
            <h2>Post App</h2>
            <p>
              This is a full stack web application created using the MERN stack. I created API endpoints to GET, POST, PUT, and DELETE and used Axios to send HTTP requests to them. I also used Mongoose to create a connection between the MongoDB database and the web application.
            </p>
          </div>
          <div className="project-image">
            <a
              href="https://murmuring-wave-93283.herokuapp.com/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/messageboardimg.png"
                alt="Screenshot of message board app"
              />
            </a>
          </div>
        </div>
      </Zoom>

      {/* <Zoom>
        <div id="project-container" className="status1">
          <div className="project-text">
            <h2>Content 2</h2>
            <p>This is Content 2</p>
            <ul>
              <li>Lorem</li>
              <li>Ipsum</li>
              <li>Dolor</li>
            </ul>
          </div>
          <div className="project-item"></div>
        </div>
      </Zoom>

      <Zoom>
        <div id="project-container" className="status1">
          <div className="project-text">
            <h2>Content 3</h2>
            <p>This is Content 3</p>
            <ul>
              <li>Lorem</li>
              <li>Ipsum</li>
              <li>Dolor</li>
            </ul>
          </div>
          <div className="project-item"></div>
        </div>
      </Zoom> */}
    </animated.div>
  );
}

