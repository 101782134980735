import React from "react";
import "./home.css";
import "./form.css";
import TopScroll from "../components/TopScroll";
import { Link } from "gatsby";

export default function Contact() {
  

  return (
    <div id="contact">
      <TopScroll />
      <h1>Contact</h1>
      <div id="contactLinks">
        <ul>
          <li>
            <Link
              to="https://www.linkedin.com/in/jeremy-crump-292883213/"
              target="_blank"
            >
              Linkedin
            </Link>
          </li>
          <li>
            <a
              href={`/Jeremy Crump2022.pdf`}
              target="_blank"
              rel="noreferrer"
              id="email"
            >
              Resume PDF
            </a>
          </li>
        </ul>
      </div>

      <h2>Send me a message</h2>
      <form name="contact" method="POST" data-netlify="true">
        <div>
          <label className="form-labels" htmlFor="name">
            Name
          </label>
          <input name="name" className="form-input" type="text" />
        </div>
        <div>
          <label className="form-labels" htmlFor="email">
            Email
          </label>
          <input name="email" className="form-input" type="email" />
        </div>
        <div>
          <label className="form-labels" htmlFor="message">
            Message [255 max characters]
          </label>
          <textarea name="message" maxLength="255" />
        </div>
        <button>Send</button>
        <input type="hidden" name="form-name" value="contact" />
      </form>
    </div>
  );
}
