import React, { Component } from 'react'
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { Link } from "gatsby";

export class TopScroll extends Component {
    render() {
        return (
          <div id="scroll-top">
            <Link to="#top">
              back <FaRegArrowAltCircleUp />
            </Link>
          </div>
        );
    }
}

export default TopScroll

