import * as React from 'react'
import Layout from '../components/Layout'
import '../components/home.css'
import PageLoad from '../components/PageLoad'
import Projects from '../components/Projects'
import Contact from '../components/Contact'

const IndexPage = ()=> { 

  return (
    <Layout pageTitle="Jeremy Crump | Developer">
      <PageLoad />

      <h2 id="bio">
        Greetings,
        <br></br>
        <br></br>
        My name is Jeremy Crump. I am a student tutor at the Milwaukee Area
        Technical College. I love to create and I have a thirst for knowledge.
        <br></br>
        <br></br>
        This website was made to showcase some of the work I have done as well
        as a place for me to test and study new languages or technologies. Feel free to take a look around or send me a message using the contact link above if you have any questions. Thanks for
        visiting.
        <br></br>
       
        <br></br>- Jeremy
      </h2>

      <Projects />
      <Contact />
      <p className='copyright'>&copy;Copyright 2020 Jeremy Crump</p>
    </Layout>
  );    
}
export default IndexPage